"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {findIn,addClass, findAllIn, on} from '@elements/dom-utils';
import {getPrefixedDataSet} from "@elements/data-set-utils";


const selectors = {
    video: '.js-play-video__video',
    overlay: '.js-play-video__overlay',
    base: '.js-play-video'
};

export function init () {
    onFind(selectors.base, function (baseElement) {
        let video = findIn(selectors.video, baseElement);
        let overlay = findIn(selectors.overlay, baseElement);

        on('click', function () {
            video.play();
            addClass('overlay-hide', baseElement);
        }, overlay);

        let data = {};
        data = {
            ...data,
            ...getPrefixedDataSet('play-video-tracking', video)
        };

        let dataLayerData = {
            'event': 'genericGtmEvent',
            'genericEventCategory': data.category,
            'genericEventAction': data.action,
            'genericEventLabel': data.label
        };

        on('play', (event) => {
            if (video.currentTime < 1) {
                window.dataLayer = window['dataLayer'] || [];
                let trackingData = {
                    ...dataLayerData,
                    ...{'genericEventLabel': "play " + Math.round(video.currentTime * 100)/100}
                };
                window.dataLayer.push(trackingData);
            }
        }, video);

        on('ended', (event) => {
            window.dataLayer = window['dataLayer'] || [];
            let trackingData = {
                ...dataLayerData,
                ...{'genericEventLabel': "end " + Math.round(video.currentTime * 100)/100}
            };
            window.dataLayer.push(trackingData);
        }, video);

        on('pause', (event) => {
            window.dataLayer = window['dataLayer'] || [];
            let trackingData = {
                ...dataLayerData,
                ...{'genericEventLabel': "pause " + Math.round(video.currentTime * 100)/100}
            };
            window.dataLayer.push(trackingData);
        }, video);

    });
}
